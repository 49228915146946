import { Children, cloneElement, ElementRef, useEffect, useRef } from 'react';

import { isElementInViewport } from 'core/utils/html-utils';
import styles from './index.module.scss';
import { MenuDirection, MenuProps, ReactKeyboardEvent } from './types';

const showMenu = ({
  isSubMenu,
  anchorEl,
  menuList,
  direction,
  menuPortalTarget,
}: {
  isSubMenu: boolean;
  anchorEl: HTMLElement;
  menuList: HTMLUListElement;
  direction: MenuDirection;
  menuPortalTarget?: HTMLElement;
}) => {
  if (!anchorEl) return;

  const rect = anchorEl.getBoundingClientRect();
  const anchorHeight = rect.height;

  menuList.removeAttribute('data-animate-hidden');
  menuList.setAttribute('style', '');
  menuList.style.visibility = 'hidden';

  if (menuPortalTarget) {
    menuList.style.top = rect.y + rect.height + 'px';

    if (direction === 'left') {
      menuList.style.right = window.innerWidth - rect.left - rect.width + 'px';
    } else {
      menuList.style.left = rect.x + 'px';
      menuList.style.right = 'unset';
    }
  } else {
    let offsetAnchorLeft = '0px';

    if (isSubMenu) {
      menuList.style.top = `calc(-1 * var(--anchor-size-offset))`;
      offsetAnchorLeft = `calc(var(--anchor-size-offset) + ${rect.width}px)`;
    }

    if (direction === 'left') {
      menuList.style.left = 'unset';
      menuList.style.right = offsetAnchorLeft;
    } else {
      menuList.style.left = offsetAnchorLeft;
      menuList.style.right = 'unset';
    }
  }

  visibilityTimer = setTimeout(() => {
    const { isFullyVisible, heightFullyVisible, widthFullyVisible } =
      isElementInViewport(menuList);

    let currentDirection = direction;

    if (!isFullyVisible) {
      let yTranslate = '0px',
        xTranslate = '0px';

      if (!widthFullyVisible) {
        currentDirection = direction === 'left' ? 'right' : 'left';

        if (isSubMenu) {
          if (direction === 'left') {
            menuList.style.right = `${0}px`;
            xTranslate = `100%`;
          } else {
            menuList.style.left = `calc(0px - var(--anchor-size-offset))`;
            xTranslate = `-100%`;
          }
        } else {
          xTranslate = `calc(-100% + ${rect.width}px)`;
        }
      }

      if (!heightFullyVisible) {
        yTranslate = `calc(-100% - ${anchorHeight}px)`;
      }

      anchorEl.setAttribute('data-current-direction', currentDirection);
      menuList.setAttribute('data-current-direction', currentDirection);
      menuList.style.transform = `translate(${xTranslate}, ${yTranslate})`;
      menuList.style.transition = 'translate 0.3s ease';
    }
    menuList.style.visibility = '';
    menuList.setAttribute('data-animate-show', 'true');
  }, 300);
};

const animateHideMenu = (
  menuList: HTMLUListElement,
  closingDelayTimeout = 800,
) => {
  clearTimeout(menuEnterTimer);
  clearTimeout(visibilityTimer);

  if (menuList) {
    menuList.removeAttribute('data-animate-show');
    menuList.setAttribute('data-animate-hidden', 'true');
    menuEnterTimer = setTimeout(() => {
      menuList.setAttribute('data-animate-hidden', 'false');
    }, closingDelayTimeout);
  }
};

const isLastActiveMenuItem = (
  menuList: HTMLUListElement,
  target: EventTarget,
) => {
  const selectableItems = menuList.querySelectorAll("[data-disabled='false']");
  const lastChild = selectableItems[selectableItems.length - 1];
  const isLastChild = lastChild!.contains(target as HTMLElement);

  return isLastChild;
};

const focusFirstActiveElement = (menuList: HTMLUListElement) => {
  if (!menuList) return;

  const target = menuList?.querySelector<HTMLElement>(
    'a,[tabindex="0"]:not([data-disabled="true"])',
  );
  target?.focus();
};

let menuEnterTimer: NodeJS.Timer;
let visibilityTimer: NodeJS.Timer;

const Menu = ({
  id,
  className,
  mounted,
  requestUnmount,
  selectFirstItem,
  isSubMenu,
  direction,
  anchorEl,
  focusNextTargetOnClose,
  menuPortalTarget,
  children,
  onClick,
  onMouseEnter,
  onOpen,
  onClose,
  onEscape,
}: MenuProps) => {
  const menuListRef = useRef<ElementRef<'ul'>>(null);

  if (requestUnmount && menuListRef.current) {
    animateHideMenu(menuListRef.current);
  }

  const validChildren = Children.toArray(children).filter(Boolean);

  const listItems = validChildren.map((child: any, index) => {
    return cloneElement(child, {
      'data-index': index,
    });
  });

  const escapeFocus = () => {
    onEscape();
    if (focusNextTargetOnClose) {
      window.queueMicrotask(() => anchorEl?.focus());
    }
  };

  useEffect(() => {
    const menuList = menuListRef.current;

    if (!menuList || !anchorEl) return;

    if (mounted) {
      showMenu({
        anchorEl,
        menuList,
        isSubMenu,
        direction,
        menuPortalTarget,
      });
    }
  }, [anchorEl, direction, mounted, menuPortalTarget, isSubMenu]);

  useEffect(() => {
    if (selectFirstItem && menuListRef.current) {
      focusFirstActiveElement(menuListRef.current);
    }
  }, [selectFirstItem]);

  useEffect(() => {
    if (!menuListRef.current) return;

    const events = ['click', 'resize'];
    events.forEach((name) => window.addEventListener(name, onClose));

    window.addEventListener('scroll', onClose, {
      capture: true,
    });

    return () => {
      clearTimeout(menuEnterTimer);
      events.forEach((name) => window.removeEventListener(name, onClose));
      window.removeEventListener('scroll', onClose);
    };
  }, [onClose]);

  useEffect(() => {
    return () => {
      clearTimeout(menuEnterTimer);
      clearTimeout(visibilityTimer);
    };
  }, []);

  return mounted ? (
    <ul
      ref={menuListRef}
      className={`${className} ${styles['list']}`}
      id={id}
      role={mounted ? 'menu' : 'presentation'}
      data-is-open={mounted && requestUnmount === false}
      tabIndex={-1}
      data-direction={direction}
      data-items-count={listItems.length}
      data-sub-menu={isSubMenu}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => {
        if (isSubMenu) {
          escapeFocus();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Tab') {
          if (isLastActiveMenuItem(menuListRef.current!, e.target)) {
            e.stopPropagation();
            escapeFocus();
          }
        } else if (e.key === 'Escape') {
          e.stopPropagation();
          escapeFocus();
        } else if (isSubMenu) {
          const currentDirection = menuListRef.current!.getAttribute(
            'data-current-direction',
          );

          if (e.key === 'ArrowRight' && currentDirection === 'right') {
            e.stopPropagation();
            escapeFocus();
          } else if (e.key === 'ArrowLeft' && currentDirection === 'left') {
            e.stopPropagation();
            escapeFocus();
          }
        }
      }}
      onAnimationEnd={(e) => {
        e.stopPropagation();
        if (e.animationName.includes('show-menu')) {
          if (selectFirstItem) {
            focusFirstActiveElement(menuListRef.current!);
          }
          onOpen(e);
        } else if (e.animationName.includes('hide-menu')) {
          onClose(e);
        }
      }}
    >
      {listItems}
    </ul>
  ) : null;
};

export { Menu };
