import { FC, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { generateId } from 'core/utils/generate-id';
import styles from './index.module.scss';
import { Menu } from './Menu';
import { MenuAnchorProps, OpenMenuEvent, MenuListProps } from './types';

let menuEnterTimer: NodeJS.Timer;
let menuCloseTimer: NodeJS.Timer;

const cleanupTimers = () => {
  clearTimeout(menuEnterTimer);
  clearTimeout(menuCloseTimer);
};

const MenuList: FC<MenuListProps> = ({
  anchorComponent,
  menuPortalTarget,
  children,
  id: propId,
  className,
  isSubMenu = false,
  disabled = false,
  menuIsOpen = false,
  focusNextTargetOnClose = false,
  closingDelayTimeout = 300,
  direction = 'right',
  onClick,
}) => {
  const [skipClickEvent, setSkipClickEvent] = useState(false);
  const [isMenuMounted, setMenuMounted] = useState(menuIsOpen);
  const [requestUnmountMenu, setRequestUnmountMenu] = useState(false);
  const [isFirstItemSelected, setFirstItemSelected] = useState(false);

  const anchorRef = useRef<HTMLElement>(null);
  const refKey = useRef(propId || generateId('menu-list-'));
  const id = refKey.current;

  const unmountMenu = () => {
    setRequestUnmountMenu(true);
  };

  const mountMenu = (e: OpenMenuEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    anchorRef.current!.focus({
      preventScroll: true,
    });

    setMenuMounted(true);
    setRequestUnmountMenu(false);

    if (isSubMenu) {
      setFirstItemSelected(true);
    } else {
      window.dispatchEvent(
        new CustomEvent('menu-list-show', {
          detail: { menuId: id },
        }),
      );
    }
  };

  const toggleMenu = (e: OpenMenuEvent) => {
    isMenuMounted ? unmountMenu() : mountMenu(e);
  };

  useEffect(() => {
    const closeOtherMenu = (e: Event) => {
      const event = e as CustomEvent;
      if (isMenuMounted && event.detail?.menuId !== id) {
        setMenuMounted(false);
      }
    };
    window.addEventListener('menu-list-show', closeOtherMenu);

    return () => {
      window.removeEventListener('menu-list-show', closeOtherMenu);
    };
  }, [id, isMenuMounted]);

  useEffect(() => {
    return () => cleanupTimers();
  }, []);

  const MenuPopup = (
    <Menu
      id={id}
      className={className}
      isSubMenu={isSubMenu}
      direction={direction}
      mounted={isMenuMounted}
      requestUnmount={requestUnmountMenu}
      anchorEl={anchorRef.current}
      selectFirstItem={isFirstItemSelected}
      menuPortalTarget={menuPortalTarget}
      focusNextTargetOnClose={focusNextTargetOnClose}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      onMouseEnter={(e) => {
        e.stopPropagation();
        cleanupTimers();
      }}
      onEscape={unmountMenu}
      onOpen={() => {
        setSkipClickEvent(false);
      }}
      onClose={() => {
        setMenuMounted(false);
        setRequestUnmountMenu(false);
        setFirstItemSelected(false);
        if (isSubMenu) {
          anchorRef.current?.focus();
        }
      }}
    >
      {children}
    </Menu>
  );
  return (
    <div
      className={styles.container}
      onMouseLeave={(e) => {
        e.stopPropagation();
        if (!isSubMenu) {
          menuCloseTimer = setTimeout(unmountMenu, closingDelayTimeout);
        }
      }}
    >
      <div className={styles['anchor-element']}>
        {anchorComponent(
          disabled
            ? ({} as MenuAnchorProps)
            : {
                ref: anchorRef,
                tabIndex: 0,
                'aria-controls': id,
                'aria-expanded': isMenuMounted,
                'aria-haspopup': 'true',
                onKeyDown: (e) => {
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    unmountMenu();
                  } else if (e.key === 'Tab') {
                    e.stopPropagation();
                    setMenuMounted(false);
                  } else if (e.key === 'Enter') {
                    e.preventDefault();
                    cleanupTimers();
                    toggleMenu(e);
                  } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    setFirstItemSelected(true);
                  } else if (isSubMenu) {
                    if (
                      (e.key === 'ArrowRight' && direction === 'right') ||
                      (e.key === 'ArrowLeft' && direction === 'left')
                    ) {
                      e.preventDefault();
                      toggleMenu(e);
                    }
                  }
                },
                onClick: (e) => {
                  e.stopPropagation();
                  if (!skipClickEvent) {
                    toggleMenu(e);
                  }
                },
                onMouseEnter: (e) => {
                  e.stopPropagation();

                  if (isMenuMounted) return;

                  setSkipClickEvent(true);
                  menuEnterTimer = setTimeout(() => {
                    mountMenu(e);
                  }, 300);
                },
                onMouseLeave: (e) => {
                  cleanupTimers();
                  setSkipClickEvent(false);
                },
              },
        )}
      </div>
      {menuPortalTarget
        ? createPortal(MenuPopup, menuPortalTarget, refKey.current)
        : MenuPopup}
    </div>
  );
};
//#endregion

export { MenuList };
export { MenuItem } from './MenuItem';
